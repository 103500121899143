.newsletter-page-wrapper {
  max-width: 1200px;
  margin: 48px auto 94px;
}

.newsletter-page-wrapper form {
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  padding: 24px;
  max-width: 527px;
}

.newsletter-page-wrapper button {
  margin-top: 24px;
}

.newsletter-page-wrapper p {
  text-align: left;
  font-size: 14px;
  margin: 0 0 24px;
}

.newsletter-page-wrapper label {
  display: block;
  margin-bottom: 2px;
}

@media (max-width: 768px) {
  .newsletter-page-wrapper {
    margin: 24px auto 48px;
  }

  .newsletter-page-wrapper {
    padding: 12px;
  }

  .newsletter-page-wrapper form {
    padding: 24px;
  }
}
